body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .route-inner .col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .search-box {
    order: -1;
    /* Move search form to top on mobile */
  }
}



@media (max-width: 768px) {
  .form-banner-reservation .form-group {
    margin-bottom: 1rem;
  }

  .search-date-picker .react-datepicker-wrapper {
    width: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.button,
.btn {
  padding: 12px 24px;
}